
.ty__content h1{
    font-size: 60px;
    color: #140046;
}
.ty__content p, .ty__footer-text p{
    font-size: 22px;
    line-height: 1.58;
    margin: 0 0 20px 0;
    color: #897fa2;
}
.ty__footer-text h2 {
    margin-bottom: 22px;
    font-size: 22px;
    color: #140046;
}

@media only screen and (max-width: 985px){
    .ty__inner {
        padding: 10px;
    }
    .ty__content h1{
        font-size: 45px;
        color: #140046;
    }
    .ty__footer-text h2 {
        margin-bottom: 6px;
        font-size: 14px;
    }
}