
.logo_header_3_desktop{  width:16rem;}

.headerSimple{ 
    padding:15px 0 !important; 
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.06) !important;
};

.headerMenu { list-style-type: none;}


.menuLinkMobile { 
    font-family: "helvetica",Arial,Helvetica,sans-serif;
    font-size: 1.2rem !important;
    color: #140046 !important;
    font-weight: 600;
}

.menuLinkMobile:hover{
    text-decoration: none;
}

.headerMenu li a:after {
    content: "";
    position: absolute;
    left: 10px;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width 300ms ease, opacity 300ms ease;
}

@media only screen and (max-width: 985px) {
    .header__logo svg path {
        fill: unset !important;   
      
    }

    .logo_header_3_mobile{  width:12rem;}

    .menuLinkMobile { 
        font-family: "helvetica",Arial,Helvetica,sans-serif;
        font-size: .6rem !important;
        color: #140046 !important;
        font-weight: 600;
    }

    .menuLinkMobile:hover{
        text-decoration: none;
    }
    .menuLinkMobile svg{transform: scale(0.7) }
};