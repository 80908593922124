/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

.dropZone {
	height: 100px;
	width: 100%;
	background-color: #fff;
	border: 2px dashed rgb(187, 186, 186);
	border-radius: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	font-size: 16px;
}
.dropZone:hover{
	background-color: rgb(188, 185, 236);

}

.Highlight {
	background-color: rgb(188, 185, 236);
}

.Icon {
	opacity: 0.3;
	height: 64px;
	width: 64px;
}

.FileInput {
	display: none;
}

@media only screen and (max-width: 985px) {
	.dropZone {
		height: 100px;
		width: 100%;
		background-color: #fff;
		/* border: none; */
		-webkit-box-align: none;
		    -ms-flex-align: none;
		        align-items: none;
		-webkit-box-pack: none;
		    -ms-flex-pack: none;
		        justify-content: none;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: none;
		        flex-direction: none;
		font-size: 16px;
		margin: 0 auto;
	}
}