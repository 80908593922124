.btn-add-purple {
    color: #fff;
    background-color: #28a745;
    width: 40%;
    border-radius: 50px;
    margin-top: 0px;
    

}
.btn-add-purple:hover {
    color: #fff;
    background-color: #3dc25c;
    width: 40%;
    border-radius: 50px;
    margin-top: 0px;
    

}

.btn-delete-linked-account{
    color: #F03E3D;
    background-color: #F5CCD4;
    border-radius: 50px;
}

.btn-delete-linked-account:hover{
    color: #c02525;
    background-color: #f1d8dd;
    border-radius: 50px;
}
