#preApproved .preApproved_header{
    background-color: #61e6a3;
    text-align: center;
    color: #fff;
    font-size: 4.5rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: -2rem;
    
}


    .h1-title {
        color: rgb(255, 255, 255);
    }

    /* text align utils */
    .text-left{ text-align: left !important;}
    .text-right{ text-align: right !important;}
    
    .display {
        display: block;
    }
    /* .space {
        padding-top: 150px;
    }
     */
    label, span {
        /* color: #927dff; */
        color: #6b1cf5;
        font-weight: 700;
        font-size: 0.9rem;
        /* padding-top: 0.7em; */
    }
    .form-control {
        color: var(--dark-purple) !important;
        font-size: 1.2rem !important;
    }
    .form-control:focus{
        border:none !important ;
    }
    .form-elegant {
        border: 0;
        border-bottom: 1px solid rgba(47, 37, 71, 0.15);
    }
    .form-elegant:focus-within {
        background: #927dff26;
    }
    
    
    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0px 9999px white;
    }
      
    .input_ele{
        padding: 13px 0;
        width: 100%;
    }
    
    
    .form-group> small{
        font-size: 10px !important;
        position: absolute;
        right: 15px;
        bottom:-15px;
    }
    
    .btn-primary {
    
        background: linear-gradient(135deg, #140046, #300155 100%) !important;
        color: #fff !important; 
        border: 0;
        border-radius: 50px;
        padding: 10px 50px 10px 50px;
        font-weight: bold;
        letter-spacing: 1px;
    }
    .btn-primary:hover {
    
        background: #2FC891 !important;
        color: #140046 !important;
        
    }
    
    .btn-success{
        width: 10% !important;
        margin-top: -38px !important;
    }
    
    .btn-light {
        position: relative;
        color: var(--dark-purple);
        background: none;
        border: 0;
        font-weight: bold;
    }
    .btn-light:hover {
        background: none;
    }
    
    .btn-light::before {
        content: "";
        position: absolute;
        left: -2px;
        top: 16px;
        display: inline-block;
        vertical-align: top;
        width: 9px;
        height: 9px;
        border-bottom: 2px solid var(--dark-purple);
        border-left: 2px solid var(--dark-purple);
        -webkit-transform: rotate(45deg) translate(0, -50%);
            -ms-transform: rotate(45deg) translate(0, -50%);
                transform: rotate(45deg) translate(0, -50%);
        margin-right: 4px;
    }
    
    
    
    .btn-add-on {
        display: inline-block;
        vertical-align: middle !important;
        margin: -.6em 0em -.5em 1.5em !important;
        transition: fill 300ms ease;
        background-color:  #43356d;
        border-radius: 18px;
        padding: 5px;
        color: rgb(255, 255, 255) !important;
    }
    
    .btn:hover .btn-add-on {
    
        background-color:  #1a9c6c;
    
    }
    
    
    /* Targetted by ID to avoid conflict  */
    
    #btnPrimaryLanding{
     
        font-size: 14px;
        letter-spacing: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
         padding: 10px;
    }
    
    #btnPrimaryModalLanding{
        font-size: 14px;
        letter-spacing: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        
    }
    
    
    .form-group> small {
        font-size: 10px !important;
        position: absolute;
        left: 15px;
        bottom: -15px;
    }
    #shadowBox {
        border-radius: 20px;
        /* background: #e6e3fc;  */
        /* background:linear-gradient(135deg, rgb(154, 86, 255) 0px, rgb(158, 108, 227) 239%); */
        padding: 2em;
        -webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
                box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
    
    
    .roundCorners {
        border-radius: 20px;
    }
    
    .controlBtnPos {
        position: absolute;
        bottom: -74px;
        right: 0px;
        z-index: 10;
    }
    
    .isDone {
        display: none;
    }
    .header.is-scrolling {
        background: rgba(255, 255, 255, 1);
    }
    .header__menu a {
        text-decoration: none !important;
    }
    
    
    /* by luis  */
    .form__infos {
        display: flex;
        max-width: 1400px;
        margin: 0 auto 20vh auto;
        padding: 0 0px;
    }
    
    /* color utils */
    .white{
        color: #ffffff;
    }
    
    .purple{
        color: #927DFE;
    }
    
    
    /* sizing utils */
    .h-1{ height: 1rem;}
    .h-2{ height: 2rem;}
    .h-4{ height: 4rem;}
    .h-8{ height: 8rem;}
    .h-16{ height: 16em;}
    .h-24{ height: 24em;}
    .h-32{ height: 32em;}
    .h-300px{ height: 300px;}

    
    .w-1{  width:1rem;}
    .w-2{  width:2rem;}
    .w-4{  width:4rem;}
    .w-8{  width:8rem;}
    .w-16{ width: 16em;}
    .w-24{ width: 24em;}
    .w-32{ width: 32em;}
    .w-100{ width: 100%;}
    
    
    /* Margin */
    
    .mt-4rem{
        margin-top: 4rem;
    }
    .mt-8rem{
        margin-top: 8rem;
    }
   
    .top-header-wave > img {
        display: block;
        position: absolute;
        z-index: -9999;
        left: 50%;
        min-width: 1920px;
        transform: translate(-50%, -30%);
        width: 100%;
    }
    
    
    label {
        color: #927dff !important;
    }
    
    
    .offer-modal{
    
        width: calc(100% - 140px);
        height: calc(100% - 140px);
        background: white;
        padding: 3rem;
        
        
    }
    
    
    
    .mobile-none{
        display: block !important;
    }
    
    .mobile{
        display: none !important;
    }
    
    
    
    .tp-widget-wrapper{
        display: none !important;
    }
    
    
      /* by Luis */
      
      .header__logo svg {
        
          margin-left: 50%;
      }
      .header__logo svg path {
          fill: #ffffff;
      }
    
      .header__logo {
        overflow: initial;
        width: unset;
        margin: auto;
    }
    
    
    .top-header-wave > img {
        
        position: absolute;
        z-index: -9999;
        left: 50%;
        min-width: 400px;
        width: 100vw;
    
    
    }
    .top-header-wave > img .mobile{
        display: block;
    
    }
    
    
    .header__logo svg {
        width: auto;
        height: 26px;
        margin: unset;
    }
    
    
    
    .mobile-text-center{
        text-align: center !important;
        
    }
    
    .offer-modal{
    
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background: white;
        padding: 3rem;
    }
    
    body {
    
        width: 100vw;
        position: absolute;
        
    }
    
    header {
    
        -ms-flex-negative: 0;
            flex-shrink: 0;
    }
    
    .header {
    
        top: 0;
        left: 0;
        width: 100%;
        padding: 25px 0;
        transition: background-color 600ms ease, box-shadow 600ms ease;
        z-index: 999 !important;
        position: unset;
    }
    
    .side-wave {
    
        left: -2vw;
        right: -4vw;
        height: auto;
        
    }
    
    h1 {
    
        font-size: 3.2rem;
        line-height: 3.1rem;
        color: #140046;
    }
    
    .item-title span{
    
        font-size: 1.18rem;
        color: #140046;
        font-weight: 700;
    }
    
    .item-text{
    
        font-size: .84rem;
        color: #8581a0;
        font-weight: 400;
    }
    
    /* text align utils */
    .text-left{ text-align: left !important;}
    .text-right{ text-align: right !important;}
    
    
    .btn-primary:hover {
    
        background: #2FC891 !important;
        color: #140046 !important;
        
    }
    
    .form-group> small {
        font-size: 10px !important;
        position: absolute;
        left: 15px;
        bottom: -15px;
    }
    
    .form__infos {
        display: flex;
        max-width: 1400px;
        margin: 0 auto 20vh auto;
        padding: 0 0px;
    }
    
    
    
    .inside-form{
        /* background: var(--dark-purple) !important; */
        background: linear-gradient(135deg, #140046, #300155 100%) !important;
        color: #fff !important;
        border: 0;
        font-size: 16px;
        border-radius: 50px;
        padding: 6px 6px 6px 12px;
        font-weight: bold;
        letter-spacing: 0px;
        width: max-content;
    }
    
    
    .btn-add-on {
        display: inline-block;
        vertical-align: middle !important;
        margin: -.6em 0em -.5em 1.5em !important;
        transition: fill 300ms ease;
        background-color:  #43356d;
        border-radius: 18px;
        padding: 5px;
        color: #fff !important;
    }
    
    .btn:hover .btn-add-on {
    
        background-color:  #1a9c6c;
    
    }
    
    
    .top-header-wave > img {
        display: block;
        position: absolute;
        z-index: -9999;
        left: 50%;
        min-width: 1920px;
        transform: translate(-50%, -30%);
        width: 100%;
    }
    
    label {
        color: #927dff !important;
    }
    
    .offer-modal{
    
        width: calc(100% - 140px);
        height: calc(100% - 140px);
        background: white;
        padding: 3rem;
        
        
    }
    
    
    
    
    /* --------------------------Media query-------------------------------------------------------------- */
    
    @media only screen and (max-width: 985px) {
        .container {
            padding: 0 1em 0 1em;
        }
    
        .background {
            background: none;
        }
        .display {
            /* display: none !important; */
        }
        /* .space {
            padding-top: 80px;
        } */
        .isDone {
            display: block;
        }
        .form-group{
            margin-bottom: 1.2rem !important;
        } 
        .form__btn-back-mobile {
            margin: 0 0 20px -8px;
            padding: 5px;
        }
        .btn-primary {
            width: 100%;
        }
        .btn-success{
            width: 100% !important;
          }
        
        .roundCorners {
            border-radius: none;
        }
    
        
    .first-in-mobile{
        order: -1;
        
    }
    
        
    
        .shadowMobile {
            -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15) !important;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15) !important;
        }
    
    
    
        .phone-ui img{
            height: 58em;
            margin-left: 18%;
            overflow: hidden !important;
    
        }
    
        .header__logo svg {
            
            margin-left: 50%;
        }
    
        .header__logo svg path {
            fill: #ffffff;
        }
    
        .header__logo {
            overflow: initial;
            width: unset;
            margin: auto;
        }
    
        .xs-mx-1{
            margin-left: 1rem !important;
            margin-right: 1rem !important;
        }
    
        .top-header-wave > img {
            
            position: absolute;
            z-index: -9999;
            left: 50%;
            min-width: 400px;
            width: 100vw;
    
        }
    
        .top-header-wave > img .mobile{
            display: block;
    
        }
    
        .btn-add-on{
            display: none !important;
        }
    
        .header__logo svg {
            width: auto;
            height: 26px;
            margin: unset;
        }
    
      
    
        .mobile-text-center{
            text-align: center !important;
            
        }
    
        .offer-modal{
    
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            background: white;
            padding: 3rem;
            
        }
    
        .mt-4rem-mobile{
            margin-top: 4rem;
        }
        .no-shadow-mobile{
            box-shadow: unset !important;
        }
        .mobile-none{
            display: none !important;
        }
        .mobile{
            display: block !important;
        }
    
        .mt-4rem-mobile{
            margin-top: 4rem;
        }
        .no-shadow-mobile{
            box-shadow: unset !important;
        }
        
        .h1-title{
            color: var(--dark-purple);
        }
    
        #btnPrimaryLanding{
     
    
            justify-content: center !important;
           
        }
        
        #btnPrimaryModalLanding{
           
            justify-content: center !important;
         
            
        }
        
       
    
    }
    
    /* --------------------------END Media query-------------------------------------------------------------- */
    
    
    
    