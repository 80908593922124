/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

:root {
	--dark-purple: #140046;
}

* {
	margin: 0 ;
	padding: 0 ;
}
html{
	height: 100%;
}

body {
	background: #fff;
	font-family: "helvetica", Arial, Helvetica, sans-serif;
	font-size: 1.4rem;
}

#root{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	min-height: 100vh;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}
.container{
	max-width: 1000px !important;
}

.content {
-webkit-box-flex: 1;
	-ms-flex: 1 0 auto ;
		flex: 1 0 auto ;
}

footer {
	-ms-flex-negative: 0;
		flex-shrink: 0;
}

.footer-bg {
	position: absolute;
	bottom: 0px;
	left: -2vw;
	right: -4vw;
	height: auto;
	z-index: -11;
}

.footer-bg svg {
    display: block;
    position: relative;
    left: 50%;
    min-width: 2050px;
    transform: translate(-50%, 0);
}

/* ---- Footer Animation -------------- */
@-webkit-keyframes bg_wave {
	from {
		opacity: 0;
		-webkit-transform: translateY(240px);
		        transform: translateY(240px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
		duration:1000;
		-webkit-transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
		        transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
	}
}

@keyframes bg_wave {
	from {
		opacity: 0;
		-webkit-transform: translateY(240px);
		        transform: translateY(240px);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
		duration:1000;
		-webkit-transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
		     -o-transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
		        transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
	}
}

.bg_animation{
	opacity: 1; -webkit-transform: translateY(0px); -ms-transform: translateY(0px); transform: translateY(0px);
	-webkit-animation: bg_wave 1.7s ease;
	        animation: bg_wave 1.7s ease;
}
.bg_animation-wave{
	opacity: 1; -webkit-transform: translateY(0px); -ms-transform: translateY(0px); transform: translateY(0px);
	-webkit-animation: bg_wave 1s ease;
	        animation: bg_wave 1s ease;
}
.bg_animation-text{
	opacity: 1; -webkit-transform: translateY(0px); -ms-transform: translateY(0px); transform: translateY(0px);
	-webkit-animation: bg_wave 2s ease;
	        animation: bg_wave 2s ease;
}


.align_bottom{
	padding-top: 237px;
	padding-bottom: 10px;
}

h1 {
	/* color: var(--dark-purple); */
	font-weight: 700;
}
h2 {
	color: var(--dark-purple);
	font-weight: 700;
}


/* loading spinner animation */
.loader {
	color: #82339b;
	font-size: 90px;
	text-indent: -9999em;
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin: 72px auto;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  }
  @-webkit-keyframes load6 {
	0% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
  }
  @keyframes load6 {
	0% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
	  -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	          box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
  }
  @-webkit-keyframes round {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @keyframes round {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }

  .spinner{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
  }
  /* -------------------------------------------------  */

.layoutFading{-webkit-animation: fadOut 2s; animation: fadOut 2s;}

.fadingOut{-webkit-animation: fadOut 5s; animation: fadOut 5s;}
.fadingIn{-webkit-animation: fadIn 5s; animation: fadIn 5s;}

  @keyframes fadOut{
	from{ opacity: 0; }
	to{ opacity: 1 ;}
  }
  @-webkit-keyframes fadOut{
	from{ opacity: 0;}
	to{ opacity: 1 ;}
  }

  @keyframes fadIn{
	from{ opacity: 1; }
	to{ opacity: 0 ;}

  }
  @-webkit-keyframes fadIn{
	from{ opacity: 1;}
	to{ opacity: 0 ;}

  }


/* ----------------slider btn flashing -------------------------------------------------*/
 .flashing{
	color:#f2f;
	  -webkit-animation: flash linear 1s infinite;
	  animation: flash linear 2s infinite;
  }
  @-webkit-keyframes flash {
	  0% { opacity: 1; }
	  50% { opacity: .5; }
	  100% { opacity: 1; }
  }
  @keyframes flash {
	  0% { opacity: 1; }
	  50% { opacity: .5; }
	  100% { opacity: 1; }
  }

 /* ------------------------------------------------------------------------------ */

.form__pages__label{
	width:-webkit-max-content;
	width:-moz-max-content;
	width:max-content;
}

#helloSign .form__pages__index{
	padding-bottom: 65px;
}
#helloSign .space{
	padding-top: 135px;
}

.vCenter{
	display: -ms-grid;
	display: grid;
	place-items: center;
	text-align: center;
	min-height: 150px;
}

.display {
	display: block;
}

.space {
	padding-top: 50px;
}

label, span {
	/* color: #927dff; */
	color: #6b1cf5;
	font-weight: 700;
	font-size: 0.9rem;
	/* padding-top: 0.7em; */
}
.form-control {
	color: var(--dark-purple) !important;
	font-size: 1.2rem !important;
}
.form-control:focus{
	border:none !important ;
}
.form-elegant {
	border: 0;
	border-bottom: 1px solid rgba(47, 37, 71, 0.15);
}
.form-elegant:focus-within {
	background: #927dff26;
}


input:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0px 9999px white;
}

.input_ele{
	padding: 13px 0;
	width: 100%;
}
/* input:invalid {
	background-color: #927dff26;
} */
input[name="opportunity__requestedAmount"] {
	font-size: 2rem;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
	color:rgba(0,0,0,0.15) !important
}
input::-moz-placeholder, textarea::-moz-placeholder {
	color:rgba(0,0,0,0.15) !important
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
	color:rgba(0,0,0,0.15) !important
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder {
	color:rgba(0,0,0,0.15) !important
}
input::placeholder, textarea::placeholder {
	color:rgba(0,0,0,0.15) !important
}

.secure_input {
    -webkit-text-security:disc;
    -mox-text-security:disc;
}

.form-group> small{
	font-size: 10px !important;
	position: absolute;
	right: 15px;
	bottom:-15px;
}

.btn-primary {
	/* background: var(--dark-purple) !important ; */
	background: linear-gradient(135deg, #140046, #300155 100%) !important;
	color: #fff !important;
	border: 0;
	border-radius: 50px;
	padding: 10px 50px 10px 50px;
	font-weight: bold;
	letter-spacing: 1.5px;
}
.btn-primary:hover {
	background: #62e6a4 !important;
	color: var(--dark-purple) !important;
}
.btn-success{
	width: 10% !important;
    margin-top: -38px !important;
}

.btn-light {
	position: relative;
	color: var(--dark-purple);
	background: none;
	border: 0;
	font-weight: bold;
}
.btn-light:hover {
	background: none;
}

.btn-light::before {
	content: "";
	position: absolute;
	left: -2px;
	top: 16px;
	display: inline-block;
	vertical-align: top;
	width: 9px;
	height: 9px;
	border-bottom: 2px solid var(--dark-purple);
	border-left: 2px solid var(--dark-purple);
	-webkit-transform: rotate(45deg) translate(0, -50%);
	    -ms-transform: rotate(45deg) translate(0, -50%);
	        transform: rotate(45deg) translate(0, -50%);
	margin-right: 4px;
}
.shadowBox {
	border-radius: 20px;
	/* background: #e6e3fc;  */
	background:linear-gradient(135deg, rgb(154, 86, 255) 0px, rgb(158, 108, 227) 239%);
	padding: 2em;
	-webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
			box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.roundCorners {
	border-radius: 20px;
}

.controlBtnPos {
	position: absolute;
	bottom: -74px;
	right: 0px;
	z-index: 10;
}

.isDone {
	display: none;
}
.header.is-scrolling {
	background: rgba(255, 255, 255, 1);
}
.header__menu a {
	text-decoration: none !important;
}

iframe #hsEmbeddedFrame{
	height: 500px;
	max-height: 500px;
}

#bankVerification.table {
	font-size: 1.2rem;
	color: var(--dark-purple);
}

#bankVerification.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(206, 199, 255, 0.2) !important;
}
#bankVerification.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(206, 199, 255, 0.4) !important;
}

 /* by rodnier */
.contact-support{
	text-align: right;
}
#filePond span{
	color:#fff;
}


/* ===========================SYNDICATOR ======================================================================= */
.ty__inner {padding: 0 100px}

@media only screen and (max-width: 985px) {

	#syndicatorLayout section {
		max-width: 100% !important;
	}
	.ty__inner {padding: 100px}
}
/* ============================================================================================================== */



/* --------------------------Media query-------------------------------------------------------------- */

@media only screen and (max-width: 985px) {
	html {
		overflow-x: hidden;
		max-width: 100vw;
	}
	.container {
		padding: 0 1em 0 1em;
	}

	.background {
		background: none;
	}
	.display {
		/* display: none !important; */
	}
	.space {
		/* padding-top: 80px; */
		padding-top: 20px;
	}
	.isDone {
		display: block;
	}
	.form-group{
		margin-bottom: 1.2rem !important;
	}
	.form__btn-back-mobile {
		margin: 0 0 20px -8px;
		padding: 5px;
	}
	.btn-primary {
		width: 100%;
	}
	.btn-success{
		width: 100% !important;
	  }
	.shadow {
		-webkit-box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0) !important;
		        box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0) !important;
	}
	.roundCorners {
		border-radius: none;
	}
	.p-5 {
		padding: 0px !important;
	}
	.css-1hwfws3 {
		font-size: 0.85rem !important;
	}
	.css-yt9ioa-option{
		font-size: 0.6em !important;
	}
	.form-control {
		font-size: 0.85rem !important;
	}
	label {
		font-size: 0.85rem;
		display: block;
	}
	.input{
		margin-bottom: 24px !important;
	}
	.input-group-text{
		font-size: 0.7rem !important;
	}

	.controlBtnPos {
		position: unset;
	}
	.align-items-end {
		height: 60px;
	}
	small {
		text-align: center;
		font-size: 1rem !important;
	}
	.spinner{
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		    -ms-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		/* top: 30%;
		left: 46%;
		-webkit-transform: translate(-30%, -46%);
		    -ms-transform: translate(-30%, -46%);
		        transform: translate(-30%, -46%); */
	  }

    /* by rodnier */
    h2, .container .title {
        font-size: 1.5rem !important;
    }
    /* p, tr, td, .content{
        font-size: 0.8rem !important;
	} */
	#checkBtn small, #checkBtn small >span{
		font-size: 0.65rem !important;
	  }
/*
	.pt-5{
		padding-top: 0px !important;
	}
	.pb-3{
		padding-bottom: 0px !important;
	}
	.pr-5{
		padding-right: 25px !important;
	}
	.pl-5{
		padding-left: 25px !important;
	} */
	h2.page-title{
		text-align: center;
	}

	.contact-support{
		text-align: center !important;
	}
	.contact-support  small{
		font-size: 0.8rem !important;
	}
	/* #signContract{
		padding-top: 70px !important;
	} */
	/* #signContractDone{
		padding-top: 70px !important;
	} */
	/* #contractReview{
		padding-top: 70px !important;
	} */
	/* #bankVerificationContainer{
		padding-top: 70px !important;
	} */
	/* #processDone{
		padding-top: 70px !important;
	}
	#uploadDocs{
		padding-top: 45px !important;
	} */

  /* #generalOfferContainer .rc-slider-mark-text strong{
	  font-size: 0.7rem !important;
  }
  #generalOfferContainer .rc-slider-track{
	height: 7px !important;
  }
  #generalOfferContainer .rc-slider-dot{
	width: 11px;
	height: 11px;
	bottom: -5px;
  }
  #generalOfferContainer .rc-slider-handle {
	width: 24px;
	height: 24px;
	margin-top: -8px;
	border: solid 3px #96dbfa;
}
  #generalOfferContainer .form-group{
	margin-bottom: 0.7rem !important;
  }  */

  	#footer-mobile-fine-print {
		flex: 0 0 100%;
		max-width: 100% !important;
 	 }

	#footer-mobile-ssl {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.mobile-logo-position {
		text-align: start !important;
	}

	#footer-mobile-information{
		flex: 0 0 90%;
		margin-left: 20px;
	}

	.footer-bg svg {
		display: block;
		position: relative;
		left: 50%;
		min-width: 3325px;
		transform: translate(-72%, 0);
	}

}


