.alert-wrapper{
    position: fixed;
    z-index: 10;
    top: -300px;  /*start from top: -300px and drop to specified height by the @keyframes   */
    left: 50%;
    transform: translateX(-50%);
}
  
.alert{
    color: #fff;
    margin: auto 0;
    padding-top: 4rem;
    text-align: center;
    /* min-height: 31vh; */
    border-radius: 10px;
    background: #927dfe;
}
  
.alert .close-mark{
    position: absolute;
    right: 1rem;
    top: 1rem;
}
.alertFiles{
    display: flex;
    flex:1;
    justify-content: center;
}
  
.alert ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: #0f0f0f
}
.alert ul > li {
    text-decoration: none;
}

@-webkit-keyframes popUpAlert {
    0%{ opacity: 0;}
    70% { -webkit-transform: translateY(525px); transform: translateY(525px); }
	100% { opacity: 1; -webkit-transform: translateY(500px); transform: translateY(500px);}
}

@keyframes popUpAlert {
    0%{ opacity: 0;}
    70% { -webkit-transform: translateY(525px); transform: translateY(525px); }
	100% { opacity: 1; -webkit-transform: translateY(500px); transform: translateY(500px);}
}

.alert_animation{
	opacity: 1; -webkit-transform: translate3d(0,0,0); -ms-transform: translate3d(0,0,0); transform: translate3d(0,0,0);
	-webkit-animation: popUpAlert 1.1s ease forward;
    animation: popUpAlert 1.1s ease forwards;
}
