/* Uplyft Button */
/* .btn.btn-uplyft {
	border-radius: 25px !important;
	padding:0.7em 1.5em;
	border:0;
	filter: drop-shadow(5px 5px 5px #ddd);

	color: #fff;
	background-color: #927dfe;
	border-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-uplyft:focus,
.btn.btn-uplyft.focus {
	color: #fff;
	background-color: #bdb2f9;
	border-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-uplyft:hover {
	color: #fff;
	background-color: #bdb2f9;
	border-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-uplyft:active,
.btn.btn-uplyft.active,
.open > .dropdown-toggle.btn.btn-uplyft {
	color: #fff;
	background-color: #bdb2f9;
	border-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-uplyft:active:hover,
.btn.btn-uplyft.active:hover,
.open > .dropdown-toggle.btn.btn-uplyft:hover,
.btn.btn-uplyft:active:focus,
.btn.btn-uplyft.active:focus,
.open > .dropdown-toggle.btn.btn-uplyft:focus,
.btn.btn-uplyft:active.focus,
.btn.btn-uplyft.active.focus,
.open > .dropdown-toggle.btn.btn-uplyft.focus {
	color: #fff;
	background-color: #ccd1d9;
	border-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-uplyft:active,
.btn.btn-uplyft.active,
.open > .dropdown-toggle.btn.btn-uplyft {
	background-image: none;
}
.btn.btn-uplyft.disabled:hover,
.btn.btn-uplyft[disabled]:hover,
fieldset[disabled] .btn.btn-uplyft:hover,
.btn.btn-uplyft.disabled:focus,
.btn.btn-uplyft[disabled]:focus,
fieldset[disabled] .btn.btn-uplyft:focus,
.btn.btn-uplyft.disabled.focus,
.btn.btn-uplyft[disabled].focus,
fieldset[disabled] .btn.btn-uplyft.focus {
	background-color: #ccd1d9;
	border-color: rgba(0, 0, 0, 0.2);
}
.btn.btn-uplyft .badge {
	color: #ccd1d9;
	background-color: #fff;
} */

/* form Button */

#generalOfferContainer .form-group{
	margin-bottom: 0rem !important;
}

.btn--lead:active {
	transform: scale(0.96) !important;
}
.btn--c-default {
	color: #fff;
	background: #140046;
	border-radius: 25px !important;
	padding:0.7em 1.5em;
	border:0;
	filter: drop-shadow(5px 5px 5px #ddd);
}
/* rc-slider-tooltip background color*/
.rc-slider-tooltip .rc-slider-tooltip-inner {
	background-color: #927dfe;
}
.rc-slider-tooltip .rc-slider-tooltip-content .rc-slider-tooltip-arrow {
	border-top-color: #927dfe;
}
.form-control:focus {
	outline: none;
	box-shadow:none !important;
	border:1px solid #927dfe;
}
.input-group-prepend, .input-group-append, .input-group-text {
	/* background-color: #e6e3fc; */
	background: linear-gradient(135deg, #927dff 0px, rgb(158, 108, 227) 239%);
    border-radius: 7px;
    color: white;
}
.rc-slider-track {
	/* background: linear-gradient(135deg,#23bdb8 0,#43e794 100%); */
	background: #6b1cf5 !important;
	background-color: #6200EE !important;
    top: 4px !important;
    height: 9px !important;
}
.rc-slider-rail {
	height: 8px !important;
	/* border: 0.6px solid #140046 !important; */
}
.rc-slider-dot {
	bottom: -4px !important;
	background: #e6e6ff !important;
}
.rc-slider-handle {
	/* background: linear-gradient(135deg,#1e3938  0,#43e794 100%) !important; */
	background-color: #6200EE !important;
	width: 25px !important;
	height: 25px !important;
	margin-top: -10px !important;
	/* border: solid 3px linear-gradient(135deg,#289cf5,#84c0ec) !important; */
	border: solid 3px rgb(128, 38, 253) !important;
}
.rc-slider-handle:hover {
	margin-top: -10px !important;
	box-shadow: 0 0 0 10px rgba(175, 23, 245, 0.2) !important;
}

.rc-slider-mark {
    top: 26px !important;
}
.rc-slider-mark-text {
	color: #6b1cf5 !important;
}
.rc-slider-mark-text-active {
	color: #666 !important;
}

.card-header {
    padding: .5rem 1.25rem;
}
.card-body {
    padding: 0.5rem 1rem;
}
.offer-summary .table-mode{
	display: none;
}
/* by rodnier */  
.offer-summary .card-mode{
	display: block;
}
label {
	color: #6b1cf5 !important;
}

/* ----------@Media Query ------------------------------------------ */
@media only screen and (max-width: 985px) {
	
	.input-group-prepend .label-prepend{
		margin: 10px 0;
		padding: 0.275rem 0.75rem 0.2rem 0.75rem;
	}
	
	.input-group-append .label-append{
		margin: 0 0 10px 0;
		padding: 0.275rem 0.75rem 0.25rem 0.75rem;
	}

	.rc-slider-mark-text strong{
		font-size: 0.7rem !important;
	}
	.rc-slider-mark {
		top: 18px !important;
	}
	.rc-slider-track{
		height: 7px !important;
		top: 5px !important;
	}

	.rc-slider-dot{
	  	bottom: -4px !important;
	}
	
	.rc-slider-handle {
		width: 24px !important;
		height: 24px !important;
		margin-top: -8px !important;
	}
	.rc-slider-handle:hover {
	  	margin-top: -8px !important;
	}
	.offer-summary .card-mode{
		display: none;
	}
	.offer-summary .table-mode{
		display: block;
	}
}






