table { width: 100% !important; }
td { padding: 10px !important;}
td > p { font-size: 0.7em; margin-bottom:0;}
strong{ font-size: 0.75em;}
hr{
    margin-top: 0rem;
    border-top: 2px solid rgba(0,0,0,.1);
}

@media only screen and (max-width: 985px) {
    .btn-sm { font-size: .5rem; }
    h5{ font-size: 0.8rem;}
    td > p { font-size: 0.7em !important; margin-bottom:0 !important;}
}