@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 5px rgba(98, 230, 164, 0.5);
		background: linear-gradient(135deg, #140046, #300155 100%);
	}

	50% {
		transform: scale(1.01);
		box-shadow: 0 0 15px rgba(98, 230, 164, 0.8);
		background: linear-gradient(135deg, rgb(98, 230, 164), rgb(60, 190, 130) 100%);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 5px rgba(98, 230, 164, 0.5);
		background: linear-gradient(135deg, #140046, #300155 100%);
	}
}

.btn-pulse {
	animation: pulse 1.5s infinite ease-in-out;
	border: none;
	color: white;
	transition: all 0.3s ease-in-out;
}
