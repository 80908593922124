.ProgressBar {
  width: 90%;
  height: 8px;
  background-color: rgb(183, 155, 229);
  border-radius: 5px;
}

.Progress {
  background-color: rgba(103, 58, 183, 1);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.progress {
  width: 90% !important;
}