.sigPad {
  width: 100%;
  height: 100%;
  min-height: 200px;
  border: 0;
  border: 2px dashed rgba(20, 0, 70, 0.15);
  background-color: rgb(247, 247, 247);
}
.sigPad:hover {
  background-color: rgb(231, 231, 231);
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: #e6e6e6;
}

.btn-clear {
  background: #9627ff;
  color: #fff;
  border-radius: 20px ;
  padding: 5px 20px 5px 20px;
  font-weight: 700;
}
.btn-sign {
  background: #140046;
  color: #fff;
  border-radius: 0px 20px 20px 0px;
  padding: 5px 20px 5px 20px;
  font-weight: 700;
}

.btn-pos {
  position: absolute;
  right: 10px;
  top: 10px;
}

.btn-clear:hover {
  background: #d8d9e7;
  color: #140046;
  font-weight: 700;
}
.btn-sign:hover {
  background: #62e6a4;
  color: #140046;
  font-weight: 700;
}

.relativePos {
  position: relative;
}

small {
  color: #140046;
  font-weight: 700;
  font-size: 0.9rem;
  padding-left: 10px;
}

#esign .exp_terms{
  font-size: 0.7rem;
  /* line-height: 0.7rem; */
}

/*------------- Terms and agreements check btn --------------- */

input[type='checkbox']{ visibility: hidden;}
/* input[type='checkbox']{ height: 0; width: 0; } */

input[type='checkbox'] + label{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: .6em 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #9e9e9e;
  -webkit-transition: color 250ms cubic-bezier(.4,.0,.23,1);
  -o-transition: color 250ms cubic-bezier(.4,.0,.23,1);
  transition: color 250ms cubic-bezier(.4,.0,.23,1);
}

input[type='checkbox'] + label > span{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1.4em;
  height: 1.4em;
  background: transparent;
  border: 2px solid #9E9E9E;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 250ms cubic-bezier(.4,.0,.23,1);
  -o-transition: all 250ms cubic-bezier(.4,.0,.23,1);
  transition: all 250ms cubic-bezier(.4,.0,.23,1);
}

input[type='checkbox'] + label:hover > span {
  background: rgba(204, 204, 204, 0.486);
}
input[type='checkbox']:checked + label > ins{ height: 100%; }

input[type='checkbox']:checked + label > span{
  border: 0.7em solid #2e1866;
  -webkit-animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
          animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
}
input[type='checkbox']:checked + label > span:before{
  content:" ";
  position: absolute;
  top: -4px;
  left: -5px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 0% 100%;
      -ms-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  -webkit-animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
          animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
}

@keyframes shrink-bounce{
  0%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  33%{
    -webkit-transform: scale(.85);
            transform: scale(.85);
  }
  100%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes shrink-bounce{
	0%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  33%{
    -webkit-transform: scale(.85);
            transform: scale(.85);
  }
  100%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  }

@keyframes checkbox-check{
  0%{
    width: 0;
    height: 0;
    border-color: #ac9cff;
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
            transform: translate3d(0,0,0) rotate(45deg);
  }
  33%{
    width: .2em;
    height: 0;
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
            transform: translate3d(0,0,0) rotate(45deg);
  }
  100%{
    width: .4em;
    height: .75em;
    border-color: #e0d9ff;
    -webkit-transform: translate3d(0,-.5em,0) rotate(45deg);
            transform: translate3d(0,-.5em,0) rotate(45deg);
  }
}
@-webkit-keyframes checkbox-check{
  0%{
    width: 0;
    height: 0;
    border-color:#ac9cff;
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
            transform: translate3d(0,0,0) rotate(45deg);
  }
  33%{
    width: .2em;
    height: 0;
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
            transform: translate3d(0,0,0) rotate(45deg);
  }
  100%{
    width: .4em;
    height: .75em;
    border-color:#e0d9ff;
    -webkit-transform: translate3d(0,-.5em,0) rotate(45deg);
            transform: translate3d(0,-.5em,0) rotate(45deg);
  }
}

@media only screen and (max-width: 985px) {

  #checkBtn small, #checkBtn small >span{
    font-size: 0.65rem !important;
  }

}
/* ---------------------------------------------------------------------------------------- */