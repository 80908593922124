/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

.Upload {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	text-align: left;
	overflow: hidden;
}

.Content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	padding-top: 27px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	width: 100%;
}

.Files {
	margin-left: 32px;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	justify-items: flex-start;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	overflow-y: auto;
}
.Files #uploadLater span{
	color: white;
    background: var(--dark-purple);
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
    padding: 9px 15px;
}
/* .Files span:hover{
	color: var(--dark-purple);
    background: #62e6a4;;
} */

.Actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	width: 100%;
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	margin-top: 32px;
}

.Title {
	margin-bottom: 32px;
	color: #555;
}

.Filename {
	font-size: 0.85rem;
	color: var(--dark-purple);
}
.none{
	display: none;
}

.Row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	height: 50px;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}

.CheckIcon {
	opacity: 0.5;
	margin: 0 10px 0 5px;

}

.ProgressWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
.ProgressWrapper .cta__button {	cursor: pointer; }

.upBtn {
	font-family: "Roboto medium", sans-serif;
	font-size: 14px;
	display: inline-block;
	height: 36px;
	min-width: 88px;
	padding: 6px 50px;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 0;
	border-radius: 20px;
	background: #140046;
	color: #fff;
	outline: 0;
}

button:disabled {
	background: rgb(189, 189, 189);
	cursor: default;
}

@media only screen and (max-width: 985px) {
	 .CheckIcon {
		margin: 0 3px 0 5px;
		-webkit-transform: scale(0.8);
		    -ms-transform: scale(0.8);
		        transform: scale(0.8);
	}
	.ProgressWrapper .cta__button {
		-webkit-transform: scale(0.8);
		    -ms-transform: scale(0.8);
		        transform: scale(0.8);
	}
	.Content {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}
	.Actions {
		/* min-height: 50px; */
		display: -ms-grid;
		display: grid;
		place-items: end;
	}
	.Files {
		margin-top: 25px;
			margin-left: 0px;
	}
	.Files h3 {
		text-align: center;
	}
	.Filename {
		font-size: 0.65rem;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		   text-overflow: ellipsis;
		white-space: nowrap;
	}
}